<template>
	<div class="integral-type el-content">
		<a-button v-has="{action:'wxapp_adhibition_integral_type_edit',plat:0}" type="primary" @click="editType(false)">
			<i class="ri-add-line"></i>添加分类
		</a-button>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'分类名称',dataIndex:'name'},
			{title:'排序',dataIndex:'rank'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #action="{record}">
				<a-space>
					<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_adhibition_integral_type_edit',plat:0}"  @click="editType(record)"></kd-button>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_adhibiton_integral_type_del',plat:0}"  @click="deleteType(record.id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getTypeList(info.page,e)}"
				@change="(e)=>{getTypeList(e,info.limit)}"
			/>
		</div>
		<a-modal v-model:visible="show.add" title="升级规则" @ok="saveType" width="600px" @cancel="show.add = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="分类名称">
					<a-input v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="排序">
					<a-input v-model:value="form.rank" type="number"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import integralModel from '@/api/addons/integral'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			show:{
				add:false
			},
			form:null
		})
		getTypeList(1,state.info.limit)

		function getTypeList(page,limit){
			integralModel.getIntType(page,limit,res=>state.info = {limit,...res})
		}

		function editType(e){
			state.form = {
				id:e.id || 0,
				name:e.name || '',
				rank:e.rank || 99
			}	
			state.show.add = true
		}

		const saveType = ()=>integralModel.addOrEditIntType(state.form,()=>{
			getTypeList(state.info.page,state.info.limit)
			state.show.add = false
		})
		const deleteType = id=>integralModel.deleteIntType(id,()=>getTypeList(state.info.page,state.info.limit) )
		return{
			...toRefs(state),
			getTypeList,
			editType,
			saveType,
			deleteType
		}
	}
}
</script>

<style lang="scss">
</style>
